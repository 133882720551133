import { useSession } from 'next-auth/react';
import React, { FC, useState } from 'react';
import { useAlert } from 'react-alert';
import { useMutation } from 'urql';
import MESSAGE from '../../../constants/WebConstants';
import useCart from '../../../hooks/cart/useCart';
import { Edges } from '../../../types/shopify/Edges.type';
import { ProductVariant } from '../../../types/shopify/products/ProductVariant.type';
import UplCartAddPreview from '../UplCartAddPreview/UplCartAddPreview';
import UplPriceTag from '../UplPriceTag/UplPriceTag';
import UplProductOption from '../UplProductOption/UplProductOption';

const AddCart = `
  mutation ($cartId: ID!, $lines: [CartLineInput!]!) {
    cartLinesAdd (cartId: $cartId, lines: $lines) {
      cart {
        id
      }
    }
  }
`;

const UplProductDetail: FC<{
  variants?: Edges<ProductVariant>;
  handle?: string;
}> = ({ variants, handle }) => {
  const [quantity, setQuantity] = useState<number | undefined>(1);
  const { data: session } = useSession();
  const [isOpen, setIsOpen] = useState(false);
  const [{ fetching }, updateTodo] = useMutation(AddCart);
  const alert = useAlert();
  const cartId = session?.user.cart.id;
  const [{ data: cartData }] = useCart({ id: cartId });

  const [edge = undefined] = variants?.edges || [];

  const handleAddCart = (id: string) => {
    if (isOpen || fetching) {
      return;
    }
    if (
      (cartData?.cart.lines.edges?.length || 0) >= 20 &&
      !cartData?.cart.lines.edges?.some((e) => e.node.merchandise.id === id)
    ) {
      alert.error(MESSAGE['error.api.001']);
      return;
    }

    const sameProduct = cartData?.cart.lines.edges.find(
      (e) => e.node.merchandise.id === id,
    );
    if ((sameProduct?.node.quantity || 0) + (quantity || 0) >= 10000) {
      alert.error(MESSAGE['error.api.002']);
      return;
    }

    const lines = [
      {
        merchandiseId: id,
        quantity,
      },
    ];

    updateTodo({ cartId, lines }).then(() => {
      setIsOpen((o) => !o);
    });
  };

  const closePreview = () => {
    setIsOpen((o) => !o);
  };

  const optionExists = edge?.node.selectedOptions?.some(
    (o) => o.name !== 'Title',
  );

  return (
    <div>
      <UplCartAddPreview closePreview={closePreview} open={isOpen} />
      {optionExists ? (
        <UplProductOption
          variants={variants?.edges || []}
          onClick={handleAddCart}
          setQuantity={setQuantity}
          handle={handle}
          quantity={quantity}
        />
      ) : (
        <UplPriceTag
          existsProduct
          selectedVariant
          price={{
            min: edge?.node.price?.amount || 0,
            max: edge?.node.compareAtPrice?.amount || 0,
          }}
          quantity={quantity}
          onChangeQuantity={setQuantity}
          sku={edge?.node.sku || ''}
          onClick={() => handleAddCart(edge?.node.id || '')}
        />
      )}
    </div>
  );
};

export default UplProductDetail;
