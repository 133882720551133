import Image from 'next/image';
import React, { FC, ReactNode } from 'react';
import cartIcon from '../../../public/Icon/shop/cart.png';
import UplText from '../UplText/UplText';

interface UplCartButtonProps {
  children: ReactNode;
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  variantCheck?: boolean;
}

const UplCartButton: FC<UplCartButtonProps> = ({
  children,
  onClick,
  variantCheck,
}) => {
  const style = !variantCheck && 'bg-theme';

  return (
    <button
      type='button'
      className={`${style} mt-4 flex w-60 items-center justify-center rounded-sm bg-gray py-3 text-white sm:w-[100%] sm:py-4`}
      onClick={onClick}
      disabled={variantCheck}
      data-cy='cart-button'
    >
      <Image
        src={cartIcon}
        height={30}
        width={30}
        alt='カート'
        className='mx-0'
      />
      <span className='ml-2 block '>
        <UplText
          variant='text'
          bold='semibold'
          color={variantCheck ? 'description' : 'ontheme'}
        >
          {children}
        </UplText>
      </span>
    </button>
  );
};

export default UplCartButton;
