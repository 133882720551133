import Link from 'next/link';
import { FC } from 'react';
import { MdCancel } from 'react-icons/md';
import ReactModal from 'react-modal';
import UplButton from '../../atoms/UplButton/UplButton';
import UplText from '../../atoms/UplText/UplText';

type Props = {
  closePreview: () => void;
  open: boolean;
};

const UplCartAddPreview: FC<Props> = ({ closePreview, open }) => (
  <ReactModal
    isOpen={open}
    closeTimeoutMS={200}
    onRequestClose={closePreview}
    className={{
      base: 'fixed right-0 top-24 z-50 flex w-80 min-w-[290px] border-l-8 border-theme bg-white shadow-xl sm:right-0 sm:top-10',
      afterOpen: 'animate-slideIn',
      beforeClose: 'animate-slideOut',
    }}
    overlayClassName={{
      base: 'fixed top-0 left-0 h-full w-full flex justify-center items-center z-40',
      afterOpen: 'animate-slideIn',
      beforeClose: 'animate-slideOut',
    }}
  >
    <div data-cy='cart-add-preview'>
      <div className='flex items-end justify-between py-4 pl-6 pr-2'>
        <div className='my-auto'>
          <UplText variant='text'>カートに追加しました</UplText>
        </div>
        <div
          onClick={closePreview}
          aria-hidden
          className='my-auto cursor-pointer text-3xl text-gray-200 hover:text-gray-300'
          data-cy='cart-add-preview-cancel-icon'
        >
          <MdCancel />
        </div>
      </div>
      <div className='ml-6 mr-12 pb-6'>
        <UplButton onClick={closePreview} aria-hidden dataCy='shop-continue'>
          お買い物を続ける
        </UplButton>
        <div className='mt-2'>
          <Link href='/cart' passHref>
            <UplButton dataCy='cart-detail'>カート詳細へ</UplButton>
          </Link>
        </div>
      </div>
    </div>
  </ReactModal>
);

export default UplCartAddPreview;
