import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import useProduct from '../../../hooks/product/useProduct';
import { Edges } from '../../../types/shopify/Edges.type';
import { ProductVariant } from '../../../types/shopify/products/ProductVariant.type';
import { SelectedOption } from '../../../types/shopify/products/SelectedOption.type';
import UplVariant from '../UplVariant/UplVariant';
import UplPriceTag from '../UplPriceTag/UplPriceTag';

const UplProductOption: FC<{
  variants: Edges<ProductVariant>['edges'];
  onClick?: (id: string) => void;
  setQuantity?: Dispatch<SetStateAction<number | undefined>>;
  handle?: string;
  quantity?: number;
}> = ({ variants, onClick, setQuantity, quantity, handle }) => {
  const [{ data: productdata }] = useProduct({
    handle: handle || '',
  });
  const [selectOption, setSelectOption] = useState<SelectedOption[]>([]);
  const [selectedAllVariant, setSelectedAllVariant] = useState(false);
  const [variant, setVariant] = useState<{
    cursor: string;
    node: ProductVariant;
  }>();

  // 全バリエーションのselectedOptionsのみを取得した配列
  const allOptions = useMemo(
    () => variants.map(({ node }) => node.selectedOptions || []),
    [variants],
  );

  const getEnableSelectOptionValues = useCallback(
    (name?: string) => {
      if (!name) return [];

      // 引数のバリエーションが選択可能かどうかに影響する選択値
      const affectOptions: SelectedOption[] = [];
      const affectOptionNames: string[] = [];
      selectOption.forEach((option) => {
        if (option.name && option.name !== name) {
          affectOptions.push(option);
          affectOptionNames.push(option.name);
        }
      });

      // 影響する選択ちが存在するかどうか
      const existsAffectOptions = !!affectOptions.length;

      // 押下可能なオプションのみにフィルターする
      const enableSelectOptions = !existsAffectOptions
        ? allOptions
        : allOptions.filter(
            (selectedOptions) =>
              selectedOptions.filter((option) => {
                const affectOptionValue = affectOptions.find(
                  (o) => o.name === option.name,
                )?.value;

                return (
                  affectOptionNames.includes(option.name || '') &&
                  (!affectOptionValue || option.value === affectOptionValue)
                );
              }).length === affectOptions.length,
          );

      // 選択可能な値の配列（重複有り）
      const enableSelectOptionValues: string[] = [];
      enableSelectOptions.flat().forEach((option) => {
        if (option.name === name && !!option.value) {
          enableSelectOptionValues.push(option.value);
        }
      });

      // 重複のない配列に変換して返却する
      return Array.from(new Set(enableSelectOptionValues));
    },
    [allOptions, selectOption],
  );

  useEffect(() => {
    if (
      selectOption.length !== 0 &&
      selectOption.length === productdata?.productByHandle.options?.length
    ) {
      setSelectedAllVariant(true);
      const selectValue = selectOption.map((o) => o.value);
      const getVariant = variants.find((v) => {
        const value = v.node.selectedOptions?.map((o) => o.value);
        return value?.sort().toString() === selectValue.sort().toString();
      });
      setVariant(getVariant);
    } else {
      setSelectedAllVariant(false);
      setVariant(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectOption]);

  const onSelect = (name: string, value?: string) => {
    const others = selectOption.filter((o) => o.name !== name);
    const newOptions = value ? [...others, { name, value }] : [...others];
    setSelectOption(newOptions);
  };

  return (
    <div className='flex items-start sm:block'>
      <div>
        {productdata?.productByHandle.options?.map((option) => (
          <div
            key={option.name}
            className='mb-6'
            data-cy='variant-button-container'
          >
            <UplVariant
              name={option.name || ''}
              values={option.values || []}
              onSelect={onSelect}
              enableValues={getEnableSelectOptionValues(option.name)}
            />
          </div>
        ))}
      </div>
      <div className='ml-4 self-end'>
        <UplPriceTag
          existsProduct={!!variant}
          selectedVariant={selectedAllVariant}
          price={{
            min: variant?.node.price?.amount || 0,
            max: variant?.node.compareAtPrice?.amount || 0,
          }}
          quantity={quantity}
          onChangeQuantity={setQuantity}
          sku={variant?.node.sku || ''}
          onClick={() => onClick && onClick(variant?.node.id || '')}
        />
      </div>
    </div>
  );
};

export default UplProductOption;
