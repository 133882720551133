import React, { FC } from 'react';
import UplCartButton from '../../atoms/UplCartButton/UplCartButton';
import UplPriceTemplate from '../../atoms/UplPriceTemplate/UplPriceTemplate';
import UplText from '../../atoms/UplText/UplText';
import UplQuantityInput from '../UplQuantityInput/UplQuantityInput';

interface UplPriceTagProps {
  selectedVariant: boolean;
  existsProduct: boolean;
  price: {
    min: number;
    max: number;
  };
  quantity?: number;
  sku: string;
  onClick: () => void;
  onChangeQuantity?: (quantity?: number) => void;
}

const UplPriceTag: FC<UplPriceTagProps> = ({
  selectedVariant,
  existsProduct,
  price: { min, max },
  quantity,
  sku,
  onClick,
  onChangeQuantity,
}) => (
  <div className='self-end rounded bg-white-gray-200 p-8 shadow-md sm:ml-0 sm:mt-2'>
    {selectedVariant && existsProduct && (
      <>
        <div
          className='mb-4 flex items-center'
          data-cy='price-tag-quantity-container'
        >
          <UplText variant='text'>個数</UplText>
          <span className='ml-2'>
            <UplQuantityInput quantity={quantity} onChange={onChangeQuantity} />
          </span>
        </div>
        <div className='text-2xl font-bold'>
          <UplPriceTemplate
            price={{
              min,
              max,
            }}
          />
        </div>
        <p data-cy='price-tag-sku-container'>
          <UplText variant='subtext' bold='semibold'>
            JANコード
          </UplText>
          <span className='ml-2'>
            <UplText variant='subtext'>{sku}</UplText>
          </span>
        </p>
      </>
    )}
    {selectedVariant && !existsProduct && (
      <div className='mt-2'>
        <UplText variant='text' color='danger' bold='semibold'>
          ご希望の商品はありません
        </UplText>
      </div>
    )}
    {!selectedVariant && (
      <div className='mt-2'>
        <UplText variant='text' color='description'>
          ご希望の商品を選択してください
        </UplText>
      </div>
    )}
    <UplCartButton
      onClick={onClick}
      variantCheck={!existsProduct || !selectedVariant || !quantity}
    >
      カートに入れる
    </UplCartButton>
  </div>
);

export default UplPriceTag;
