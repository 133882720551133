import { gql, useQuery } from 'urql';
import { useQuery as useReactQuery } from 'react-query';
import { useEffect, useMemo, useState } from 'react';
import { Image } from '../../types/shopify/commons/Image.type';
import { Edges } from '../../types/shopify/Edges.type';
import { Collection } from '../../types/shopify/products/Collection.type';
import { Product } from '../../types/shopify/products/Product.type';
import { ProductVariant } from '../../types/shopify/products/ProductVariant.type';
import { ApiBaseResponse } from '../../types/api/ApiBaseResponse.type';
import HttpStatusCode from '../../constants/HttpStatusCode';
import MESSAGE from '../../constants/WebConstants';

export const VariantFragments = gql`
  fragment Variant on ProductVariant {
    id
    sku
    title
    availableForSale
    compareAtPrice {
      amount
      currencyCode
    }
    price {
      amount
      currencyCode
    }
    unitPrice {
      amount
      currencyCode
    }
    selectedOptions {
      name
      value
    }
  }
`;

const getProduct = gql`
  query GetProduct($handle: String!) {
    productByHandle(handle: $handle) {
      id
      title
      vendor
      descriptionHtml
      productType
      options(first: 20) {
        name
        values
      }
      collections(first: 10) {
        nodes {
          handle
          id
          title
        }
      }
      images(first: 250) {
        edges {
          node {
            id
            width
            height
            url
          }
        }
      }
      variants(first: 100) {
        edges {
          cursor
          node {
            ...Variant
          }
        }
      }
      productTags: metafield(key: "tags", namespace: "custom") {
        key
        value
      }
    }
  }

  ${VariantFragments}
`;

const getProductVariants = gql`
  query GetProduct($handle: String!) {
    productByHandle(handle: $handle) {
      title
      variants(first: 100) {
        edges {
          cursor
          node {
            ...Variant
          }
        }
      }
    }
  }

  ${VariantFragments}
`;

export interface IUseProduct {
  /**
   * 商品検索の一意キー
   */
  handle: string;
}

const useProductAuth = (handle: string, enabled: boolean) =>
  useReactQuery<ApiBaseResponse, Error>(
    'productAuth',
    async () => {
      const res = await fetch(`/api/products/auth/${handle}`);

      if (res.status !== HttpStatusCode.OK) {
        throw new Error(MESSAGE['error.front.001']);
      }
      try {
        const json = (await res.json()) as ApiBaseResponse;
        if (json.statusCode !== HttpStatusCode.OK) {
          throw new Error(json.errors.join(' '));
        }
        return json;
      } catch {
        throw new Error(MESSAGE['error.front.001']);
      }
    },
    {
      retry: 0,
      enabled,
    },
  );

export type ProductQueryResult = {
  productByHandle: {
    collections: Edges<Collection>;
    images: Edges<Image>;
    variants: Edges<ProductVariant>;
    productTags: {
      key: string;
      value: string;
    };
  } & Product;
};
/**
 * [名前] 商品検索
 * [概要] 条件により、商品を取得する。（自動で取得しないよう、pause: trueとする。 使用する場合、親からfetchを実行すること）
 * @param IUseProducts 商品検索の取得数
 */
const useProduct = ({ handle }: IUseProduct) => {
  const [data, setData] = useState<ProductQueryResult>();
  const { data: authData, isLoading, isError } = useProductAuth(handle, true);

  const [{ data: queryData, fetching, error }] = useQuery<ProductQueryResult>({
    query: getProduct,
    variables: { handle },
    pause: authData?.statusCode !== HttpStatusCode.OK,
  });

  useEffect(() => {
    if (!isError) {
      setData(queryData);
    } else {
      setData(undefined);
    }
  }, [isError, queryData]);

  const tags = useMemo(() => {
    const value = data?.productByHandle?.productTags?.value;
    return (
      value
        ?.replace(/[\[\]"]/g, '')
        .split(',')
        .filter((tag) => !!tag) || []
    );
  }, [data]);

  return [
    {
      data: authData?.statusCode === HttpStatusCode.OK ? data : undefined,
      tags,
      fetching: fetching || isLoading,
      error,
    },
  ];
};

type VariantQueryResult = {
  productByHandle: {
    variants: Edges<ProductVariant>;
  } & Product;
};
/**
 * [名前] 商品検索
 * [概要] 条件により、商品バリエーションを取得する。（自動で取得しないよう、pause: trueとする。 使用する場合、親からfetchを実行すること）
 * @param IUseProducts 商品検索の取得数
 */
export const useProductVariants = ({
  handle,
  pause,
}: IUseProduct & { pause: boolean }) => {
  const [data, setData] = useState<VariantQueryResult>();
  const { data: authData, isLoading, isError } = useProductAuth(handle, !pause);

  const [{ data: queryData, fetching, error }] = useQuery<VariantQueryResult>({
    query: getProductVariants,
    variables: { handle },
    pause: pause || authData?.statusCode !== HttpStatusCode.OK,
  });

  useEffect(() => {
    if (!isError) {
      setData(queryData);
    } else {
      setData(undefined);
    }
  }, [isError, queryData]);

  return [
    {
      data: authData?.statusCode === HttpStatusCode.OK ? data : undefined,
      fetching: fetching || isLoading,
      error,
    },
  ];
};

export default useProduct;
