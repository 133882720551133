import { FC, useState } from 'react';
import UplText from '../../atoms/UplText/UplText';

interface UplVariantProps {
  name: string;
  values: string[];
  enableValues: string[];
  onSelect: (value: string, name: string) => void;
}

const UplVariant: FC<UplVariantProps> = ({
  name,
  values,
  enableValues,
  onSelect,
}) => {
  const [currentValue, setCurrentValue] = useState('');

  const onClick = (value: string) => {
    const v = currentValue === value ? '' : value;
    setCurrentValue(v);
    onSelect(name, v);
  };

  return (
    <>
      <div>
        <UplText variant='text'>{name}を選択</UplText>
      </div>
      <div className='my-3 ml-4 flex flex-wrap'>
        {values.map((v) => {
          const disabled = !enableValues.includes(v);
          return (
            <button
              onClick={() => onClick(v)}
              key={v}
              className={`${
                v === currentValue ? 'border-theme bg-theme text-white' : ''
              } mx-1 mb-2 whitespace-nowrap rounded border-2 border-gray px-3 py-1 ${
                disabled ? 'bg-white-gray' : 'cursor-pointer'
              }`}
              disabled={disabled}
              data-cy='variant-button'
            >
              <UplText
                variant='text'
                color={v === currentValue ? 'ontheme' : 'primary'}
                bold={v === currentValue ? 'semibold' : 'medium'}
                dataCy='variant-button-text'
              >
                {v}
              </UplText>
            </button>
          );
        })}
      </div>
    </>
  );
};

export default UplVariant;
